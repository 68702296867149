import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Input,
  Stack,
  TextField,
  Typography,
  Fade,
  Alert,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {HelpPopover} from "../components/HelpPopover";
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  CheckCircle,
  ClearAll,
  ClearAllOutlined,
  ClearRounded,
  ClearTwoTone,
  ExpandMore,
  HighlightOff,
  Warning,
} from "@mui/icons-material";
import toast, {Toaster} from "react-hot-toast";

import supabase from "../config/supabaseClient";
import {useAuth} from "../contexts/useAuth";
import {useNavigate} from "react-router-dom";
import {Combobox, InputBase, useCombobox, Input as MantineInput, Image} from "@mantine/core";
import {LoadingButton} from "@mui/lab";

// Use localstorage to store the user's 'start' a company progress

export default function Start() {
  const [loading, setLoading] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState();
  const [isNumbered, setIsNumbered] = React.useState();
  const [reqCompanyName, setReqCompanyName] = React.useState();
  const [hasNuans, setHasNuans] = React.useState();

  // console.log("localStorage.getItem('currentStep')", localStorage.getItem("currentStep"));

  // This function sets the current step in localstorage and in state
  function navStep(step) {
    console.log("navStep", step);
    localStorage.setItem("currentStep", step);
    setCurrentStep(step);
  }

  // This component will render the content based on the current step from state
  const Content = ({currentStep}) => {
    switch (currentStep) {
      case "1":
        return <ProvinceCheck navStep={navStep} />;
      case "2":
        return <NamedOrNumbered navStep={navStep} setIsNumbered={setIsNumbered} currentStep={currentStep} />;
      case "3":
        return <AskIfHasNuans navStep={navStep} currentStep={currentStep} />;
      case "4":
        return <Presearch navStep={navStep} currentStep={currentStep} />;
      case "5":
        return (
          <CreateEntity navStep={navStep} currentStep={currentStep} isNumbered={isNumbered} reqCompanyName={reqCompanyName} hasNuans={hasNuans} />
        );

      default:
        return <Skeleton />;
    }
  };

  // This useEffect will run when the component mounts and will check if the currentStep is in localstorage and set it in state
  // If it's not in localstorage, it will set it to 1
  // This will allow the user to continue where they left off
  useEffect(() => {
    setLoading(true);
    function getCurrentStep() {
      if (localStorage.getItem("currentStep") == undefined) {
        console.log("undefined");
        localStorage.setItem("currentStep", "1");
        setCurrentStep("1");
      } else {
        setCurrentStep(localStorage.getItem("currentStep") ? localStorage.getItem("currentStep") : "1");
      }
    }

    function getIsNumbered() {
      if (localStorage.getItem("isNumbered") == undefined) {
        console.log("undefined");
        localStorage.setItem("isNumbered", "false");
      } else {
        setIsNumbered(localStorage.getItem("isNumbered") ? localStorage.getItem("isNumbered") : "false");
      }
    }

    function getReqCompanyName() {
      if (localStorage.getItem("reqCompanyName") == undefined) {
        console.log("undefined");
        localStorage.setItem("reqCompanyName", "");
      } else {
        setReqCompanyName(localStorage.getItem("reqCompanyName") ? localStorage.getItem("reqCompanyName") : "");
      }
    }

    function getHasNuans() {
      if (localStorage.getItem("hasNuans") == undefined) {
        console.log("undefined");
        localStorage.setItem("hasNuans", "false");
      } else {
        setHasNuans(localStorage.getItem("hasNuans") ? localStorage.getItem("hasNuans") : "false");
      }
    }

    getCurrentStep();
    getIsNumbered();
    getReqCompanyName();
    getHasNuans();
    setLoading(false);
  }, [currentStep]);

  return (
    <>
      <Box sx={{m: "10px"}}>
        <Card variant="outlined" sx={{bgcolor: "white"}}>
          <CardContent sx={{padding: {xs: "20px", md: "30px"}}}>{loading ? <Skeleton /> : <Content currentStep={currentStep} />}</CardContent>
        </Card>
      </Box>
    </>
  );
}

function ProvinceCheck({navStep}) {
  const [province, setProvince] = React.useState("");
  const [canProceed, setCanProceed] = React.useState(false);
  const [value, setValue] = React.useState("");

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const provinceList = [
    {
      id: 0,
      name: "Ontario",
      code: "ON",
    },
    {
      id: 1,
      name: "Alberta",
      code: "AB",
    },
    {
      id: 2,
      name: "British Columbia",
      code: "BC",
    },
    {
      id: 3,
      name: "Manitoba",
      code: "MB",
    },
    {
      id: 4,
      name: "New Brunswick",
      code: "NB",
    },
    {
      id: 5,
      name: "Newfoundland and Labrador",
      code: "NL",
    },
    {
      id: 6,
      name: "Northwest Territories",
      code: "NT",
    },
    {
      id: 7,
      name: "Nova Scotia",
      code: "NS",
    },
    {
      id: 8,
      name: "Nunavut",
      code: "NU",
    },

    {
      id: 9,
      name: "Prince Edward Island",
      code: "PE",
    },
    {
      id: 10,
      name: "Quebec",
      code: "QC",
    },
    {
      id: 11,
      name: "Saskatchewan",
      code: "SK",
    },
    {
      id: 12,
      name: "Yukon",
      code: "YT",
    },
  ];

  const options2 = (
    <Combobox.Options>
      <Combobox.Group label="Default">
        <Combobox.Option value="Ontario">
          <div className="font-bold">Ontario</div>
        </Combobox.Option>
      </Combobox.Group>
      <Combobox.Group label="Federal">
        <Combobox.Option value="Canada (Federal)">Canada (Federal)</Combobox.Option>
      </Combobox.Group>
      <Combobox.Group label="Other Provinces">
        {provinceList
          .filter((province) => province.name !== "Ontario")
          .map((province) => (
            <Combobox.Option value={province.name} key={province.id}>
              {province.name}
            </Combobox.Option>
          ))}
      </Combobox.Group>
    </Combobox.Options>
  );

  function checkSelection({value}) {
    // Logic to check if the selected province is valid

    setProvince(value);

    if (value === "Ontario") {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }

  function handleProvinceCheck(e) {
    // set the selected province in state
    setProvince(e.target.value);

    // check if the selected province is a supported province and set the canProceed state
    if (e.target.value === "Ontario") {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }

  function handleNext() {
    navStep(2);
  }

  return (
    <Stack direction={"row"} spacing={2} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
      <Box width={{xs: 200, md: 300}}>
        <Image src="/header/city-which-province.svg" alt="Numbered" />
      </Box>
      <Stack spacing={2} maxWidth={"600px"}>
        <Typography variant="h5" color={"primary"}>
          Which province do you want to incorporate in?
        </Typography>
        <Typography variant="body1">Your should incorporate where your business is going to operate.</Typography>
        {/* <Stack spacing={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="province-select-label">Select a province</InputLabel>
            <Select value={province} onChange={(e) => handleProvinceCheck(e)} label="Select a province">
              {provinceList.map((province) => (
                <MenuItem key={province.id} value={province.name}>
                  {province.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack> */}
        <Stack spacing={2}>
          <Combobox
            store={combobox}
            // withinPortal={false}
            onOptionSubmit={(val) => {
              setValue(val);
              combobox.closeDropdown();
              checkSelection({value: val});
            }}
          >
            <Combobox.Target>
              <InputBase
                component="button"
                type="button"
                pointer
                rightSection={<Combobox.Chevron />}
                onClick={() => combobox.toggleDropdown()}
                rightSectionPointerEvents="none"
              >
                {value || "Select a province"}
              </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options mah={200} style={{overflowY: "auto"}}>
                {options2}
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        </Stack>
        <Stack spacing={2} direction="row" justifyContent={"end"}>
          {province && canProceed && (
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          )}
          {province && !canProceed && (
            <Card variant="outlined" sx={{bgcolor: "card.main"}}>
              <Stack spacing={2} p={2}>
                <Typography>
                  Currently, only Ontario is supported. Please check back later for other jurisdictions, or use our{" "}
                  <a href="https://www.skylaunch.ca/contact" target="_blank" rel="noreferrer">
                    contact form
                  </a>{" "}
                  to connect with our sister firm{" "}
                  <a href="https://skylaw.ca" target="_blank" rel="SkyLaw Website noreferrer">
                    SkyLaw
                  </a>{" "}
                  for assistance.
                </Typography>
              </Stack>
            </Card>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

function NamedOrNumbered({navStep, setIsNumbered, currentStep}) {
  function handleSelectNumbered() {
    localStorage.setItem("isNumbered", "true");
    localStorage.setItem("reqCompanyName", "");
    localStorage.setItem("hasNuans", "false");
    setIsNumbered(true);
    navStep(5);
  }

  function handleSelectNamed() {
    localStorage.setItem("isNumbered", "false");
    localStorage.setItem("reqCompanyName", "");
    localStorage.setItem("hasNuans", "false");
    setIsNumbered(false);
    navStep(3);
  }

  return (
    <>
      <Stack direction={"row"} spacing={2} display={"flex"} alignItems={"center"} justifyContent={"start"} paddingX={3} paddingTop={3}>
        <IconButton color="primary" onClick={() => navStep(1)}>
          {currentStep > 1 && <ArrowCircleLeft color="primary" sx={{width: "30px", height: "30px"}} />}
        </IconButton>
      </Stack>
      <Stack spacing={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Stack spacing={2} direction={{xs: "column", md: "row"}} justifyContent={"space-between"}>
          <Card variant="outlined" sx={{bgcolor: "card.main"}}>
            <CardContent sx={{padding: "20px", width: "330px"}}>
              <Stack spacing={2}>
                <img src="/header/computer-numbered.svg" alt="Numbered" />
                <Typography variant="h5" fontWeight={600} color={"primary"}>
                  Numbered Company
                </Typography>
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                  <Chip label="QUICKEST" size="small" sx={{bgcolor: "chip.green", color: "white", fontSize: "11px", fontWeight: 600}} />
                  <HelpPopover>
                    <Stack spacing={1} p={3} maxWidth={300}>
                      <Typography sx={{p: 1}}>
                        A numbered company is the quickest way to incorporate. At incorporation, your company will be assigned a number that will
                        become its name (e.g., 123456 Ontario Inc.)
                      </Typography>
                    </Stack>
                  </HelpPopover>
                </Stack>
                <Typography variant="body1">I want to set up a numbered company like: 123456 Ontario, Inc.</Typography>
                <Button variant="contained" color="primary" onClick={handleSelectNumbered}>
                  Select
                </Button>
              </Stack>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{bgcolor: "card.main"}}>
            <CardContent sx={{padding: "20px", width: "330px"}}>
              <Stack spacing={2} justifyContent={"space-between"}>
                <img src="/header/computer-named.svg" alt="Numbered" />
                <Typography variant="h5" fontWeight={600} color={"primary"}>
                  Named Company
                </Typography>
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                  <Chip label="NAME CHOICE" size="small" sx={{bgcolor: "chip.orange", color: "white", fontSize: "11px", fontWeight: 600}} />
                  <HelpPopover>
                    <Stack spacing={1} p={3} maxWidth={300}>
                      <Typography sx={{p: 1}}>
                        For a Named Company you’ll need a Nuans Name Reservation (we can obtain one for you if you don’t already have one) to make
                        sure no one else is using or has trademarked that name. Your company’s name should also have three elements: a distinctive
                        name (“Acme”), a descriptive element (“Widgets”) and a legal suffix (Ltd.). Check out our blog{" "}
                        <a href="https://www.skylaunch.ca/resources/whats-in-a-name" target="_blank" rel="noreferrer">
                          here
                        </a>{" "}
                        if you want more information.
                      </Typography>
                    </Stack>
                  </HelpPopover>
                </Stack>
                <Typography variant="body1">I want to set up a named company like: Acme Widgets Ltd.</Typography>
                <Button variant="contained" color="primary" onClick={handleSelectNamed}>
                  Select
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </>
  );
}

function AskIfHasNuans({navStep, currentStep}) {
  function handleNoNuans() {
    localStorage.setItem("hasNuans", "false");
    navStep(4);
  }

  function handleYesNuans() {
    localStorage.setItem("hasNuans", "true");
    navStep(5);
  }

  return (
    <>
      <Stack direction={"row"} spacing={2} display={"flex"} alignItems={"center"} justifyContent={"start"} paddingX={3} paddingTop={3}>
        <IconButton color="primary" onClick={() => navStep(2)}>
          {currentStep > 1 && <ArrowCircleLeft color="primary" sx={{width: "30px", height: "30px"}} />}
        </IconButton>
      </Stack>

      <Stack spacing={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Stack spacing={2} direction={{xs: "column", md: "row"}} justifyContent={"space-between"} alignItems={"center"}>
          <Box width={{xs: 200, md: 300}}>
            <img src="/header/computer-named.svg" alt="Numbered" />
          </Box>
          <Stack spacing={2} display={"flex"} maxWidth={500} justifyContent={"center"} alignItems={"center"}>
            <Stack spacing={1} display={"flex"} direction="row" justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontSize={"16px"} fontWeight={400} color={"black"}>
                Do you have a Nuans* Name Reservation?
              </Typography>
              <HelpPopover>
                <Stack spacing={1} p={3} maxWidth={300}>
                  <Typography sx={{p: 1}}>
                    To incorporate a named company in Ontario you need an “Ontario-weighted” Nuans Name Reservation which is valid for 90 days. If you
                    don’t have one, we can obtain one for you.
                  </Typography>
                </Stack>
              </HelpPopover>
            </Stack>
            <Stack spacing={2} direction="row" justifyContent={"space-between"} alignItems={"center"}>
              <Button variant="contained" color="primary" onClick={handleYesNuans}>
                Yes
              </Button>
              <Button variant="contained" color="primary" onClick={handleNoNuans}>
                No
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Typography variant="subtitle2" color={"text.secondary"} sx={{textAlign: "center"}}>
          *Nuans is a registered trademark and official mark of the Government of Canada, used under sublicence. SkyLaunch is providing Nuans Search
          Reports generated using the Nuans System and is otherwise not associated with the Government of Canada.
        </Typography>
      </Stack>
    </>
  );
}

function Presearch({navStep, currentStep}) {
  const [name, setName] = React.useState("");
  const [distinctiveTerm, setDistinctiveTerm] = React.useState("");
  const [nameError, setNameError] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [totalFound, setTotalFound] = useState(0);
  const [countCompanyCitation, setCountCompanyCitation] = useState(0);
  const [countTrademarkCitation, setCountTrademarkCitation] = useState(0);
  const [recommendUse, setRecommendUse] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false); //default to false
  const [showResults, setShowResults] = useState(false);

  const nameInputRef = React.useRef(null);

  async function handleSearch(name, distinctiveTerm) {
    setIsSearching(true);
    if (!name.trim()) {
      setNameError(true);
      setIsSearching(false);
      return;
    } else {
      setNameError(false);
    }

    // ! cleanup - change to updated env selection via env: APP_MODE
    const dev_url = `http://localhost:5001/presearch?name=${name}&distinctiveTerm=${distinctiveTerm}`;
    const prod_url = `https://skylaunch-api-nuans-production.up.railway.app/presearch?name=${name}&distinctiveTerm=${distinctiveTerm}`;

    const apiUrl = window.location.hostname === "localhost" ? dev_url : prod_url;
    // console.log("apiUrl", apiUrl);

    const requestOptions = {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_API_KEY}`,
      },
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();

      if (data.totalFound > 0) {
        setCountCompanyCitation(data.companyCitation.length);
        setCountTrademarkCitation(data.trademarkCitation.length);
        setTotalFound(data.totalFound);

        // set recommendation to false as we found existing companies with matching names and trademarks
        setRecommendUse(false);

        // set visibility of results
        setShowRecommendation(true);
        setShowResults(true);

        //reset search for loading indicator
        setIsSearching(false);
      } else {
        //set result values
        setTotalFound(0);
        setCountCompanyCitation(0);
        setCountTrademarkCitation(0);

        // set visibility of results
        setShowRecommendation(true);
        // setShowResults(true);

        // set recommendation to true as we did not find any existing companies with matching names and trademarks
        setRecommendUse(true);

        console.log("No data returned");
        setIsSearching(false);
      }

      // return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("We had some trouble checking the name. Please try again.");
      setIsSearching(false);
      throw error;
    }
  }

  const handleClearSearchInput = () => {
    setName("");
    setNameError(false);
    setShowResults(false);
    setShowRecommendation(false);
  };

  const handleProceedNamed = () => {
    // save the named to state & localstorage
    localStorage.setItem("reqCompanyName", name);
    localStorage.setItem("isNumbered", "false");

    // navigate to the entity created page
    navStep(5);
  };

  const handleProceedNumbered = () => {
    // save the numbered to state & localstorage
    localStorage.removeItem("reqCompanyName");
    localStorage.setItem("isNumbered", "true");

    // navigate to the entity created page
    navStep(5);
  };

  const handdleTryAnotherName = () => {
    setShowResults(false);
    setShowRecommendation(false);
    setName("");
    setNameError(false);
    nameInputRef.current.focus();
  };

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  return (
    <>
      <Stack direction={"row"} spacing={2} display={"flex"} alignItems={"center"} justifyContent={"start"} paddingTop={1}>
        <IconButton color="primary" onClick={() => navStep(3)}>
          {currentStep > 1 && <ArrowCircleLeft color="primary" sx={{width: "30px", height: "30px"}} />}
        </IconButton>
      </Stack>

      <Stack spacing={2} display={"flex"} alignItems={"center"} justifyContent={"center"} paddingBottom={4}>
        <Stack spacing={2} direction={{xs: "column", md: "row"}} justifyContent={"space-between"} alignItems={"center"}>
          <Box width={"300px"}>
            <img src="/header/computer-named.svg" alt="Numbered" />
          </Box>
          <Stack spacing={3} display={"flex"} maxWidth={500} justifyContent={"start"} alignItems={"start"}>
            <Stack spacing={1} display={"flex"} direction="column" justifyContent={"space-between"} alignItems={"start"}>
              <Typography fontSize={"16px"} fontWeight={400} color={"black"}>
                Start by entering unique name for your company below.
              </Typography>

              <Typography fontSize={"16px"} fontWeight={600} color={"black"}>
                You’ll be able to select the legal ending (i.e. Inc.) later.
              </Typography>
              <Alert severity="info" sx={{width: "100%"}}>
                Example: “Acme Widgets”.
              </Alert>

              {/* <Typography fontSize={"16px"} fontWeight={400} color={"black"}>
                The name must include a distinctive element and a legal suffix (such as “Acme Inc.”) and ideally a descriptive element also (such as
                “Acme Widgets Inc.”).
              </Typography> */}
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={2} direction="column" display={"flex"} justifyContent={"start"} alignItems={"center"} width={{xs: "100%", md: "700px"}}>
          <Stack spacing={2} direction="row" display={"flex"} justifyContent={"start"} alignItems={"center"} width={"100%"}>
            <TextField
              id="proposed-name"
              name="proposedName"
              label="Proposed Company Name"
              variant="outlined"
              value={name}
              error={nameError}
              helperText={nameError && "Please enter a name"}
              fullWidth
              onChange={(e) => (setName(e.target.value), setDistinctiveTerm(e.target.value.split(" ")[0]), setNameError(false))}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(name, distinctiveTerm);
                }
              }}
              inputRef={nameInputRef}
            />
            {name && (
              <IconButton color="primary" onClick={handleClearSearchInput}>
                <HighlightOff />
              </IconButton>
            )}
          </Stack>
          <Stack spacing={2} direction="row" display={"flex"} justifyContent={"start"} alignItems={"center"} width={"100%"}>
            <LoadingButton variant="contained" color="primary" onClick={() => handleSearch(name, distinctiveTerm)} loading={isSearching}>
              Search
            </LoadingButton>
          </Stack>
        </Stack>
        {/* {nameError && <Typography color={"notification.main"}>Please enter a name</Typography>} */}
        {showRecommendation && (
          <Stack direction={"row"} spacing={2} display={"flex"} alignItems={"start"} justifyContent={"end"} width={{xs: "100%", md: "700px"}}>
            <Card sx={{bgcolor: "card.main", width: "100%"}}>
              <CardContent>
                {recommendUse ? (
                  <Stack spacing={2} direction={"row"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <CheckCircle sx={{color: "icon.green", width: "30px", height: "30px"}} />
                    <Typography>Looks like you can use this name. No other companies are using this name or trademark.</Typography>
                    <Button variant="contained" onClick={handleProceedNamed}>
                      Proceed
                    </Button>
                  </Stack>
                ) : (
                  <Stack spacing={2} direction={"column"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack spacing={4} direction={"column"} display={"flex"} justifyContent={"start"}>
                      <Stack spacing={2} direction={"row"} display={"flex"} alignItems={"center"} justifyContent={"start"}>
                        <Warning sx={{color: "icon.red", width: "30px", height: "30px"}} />
                        <Typography>
                          We do not recommend using this name as we found existing comopanies with matching names. However, you can proceed at your
                          own risk.
                        </Typography>
                      </Stack>
                      <Stack spacing={2} direction={"row"} display={"flex"} justifyContent={"space-between"}>
                        <Button variant="outlined" onClick={handdleTryAnotherName}>
                          Try Another Name
                        </Button>
                        <Button variant="contained" onClick={handleProceedNumbered}>
                          Create Numbered Company Instead
                        </Button>
                      </Stack>
                      {/* <Accordion> */}
                      {/* <AccordionSummary expandIcon={<ExpandMore />}>View Results</AccordionSummary> */}

                      <Stack padding={3} spacing={2} direction={"column"} display={"flex"} alignItems={"start"}>
                        <Typography>Total Found: {totalFound}</Typography>
                        <Typography>Company Citations: {countCompanyCitation}</Typography>
                        <Typography>Trademark Citations: {countTrademarkCitation}</Typography>
                      </Stack>
                      {/* </Accordion> */}
                    </Stack>
                  </Stack>
                )}
              </CardContent>
            </Card>
          </Stack>
        )}
      </Stack>
      {/* <pre>{JSON.stringify({ name, distinctiveTerm }, null, 2)}</pre> */}
      <Toaster />
    </>
  );
}

function CreateEntity({navStep, currentStep, isNumbered, reqCompanyName, hasNuans}) {
  const {user} = useAuth();
  const navigate = useNavigate();

  async function handleNext() {
    let companyName = reqCompanyName;

    if (isNumbered === "true") {
      companyName = "### Ontario Inc.";
    }

    try {
      //! Customer App Journey: create a new company
      // create the entity in supabase
      const {data: entity, error: errorEntity} = await supabase
        .from("entity")
        .insert([
          {
            linked_user: user.id,
            name: companyName,
            english_name: companyName,
            has_nuans_report: hasNuans,
            is_numbered: isNumbered,
            is_presearch_recommended: false,
            status: "New",
            legal_element_ending: "Inc.",
          },
        ])
        .select("*");

      // Debug
      console.log("data: entity created:", entity);
      console.log("error: entity created:", errorEntity);

      // create the user_role record if the entity was created successfully
      if (entity) {
        const {data: dataUserRole, error: errorUserRole} = await supabase
          .from("user_role")
          .insert([
            {
              member_id: user.id,
              entity_id: entity[0].id,
              role: "customer",
            },
          ])
          .select("*");

        if (errorUserRole) {
          console.error("Error fetching data:", errorUserRole);
          toast.error("We had some trouble creating the entity. Please try again.");
          throw errorUserRole;
        }

        if (dataUserRole) {
          console.log("dataUserRole", dataUserRole);
          toast.success("Access record for entity created successfully");
        }
      }

      // clear localstorage
      localStorage.removeItem("currentStep");
      localStorage.removeItem("isNumbered");
      localStorage.removeItem("reqCompanyName");
      localStorage.removeItem("hasNuans");

      // navigate to the entity created page
      navigate(`/setup/entity/${entity[0].id}`);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("We had some trouble creating the entity. Please try again.");
      throw error;
    }
  }

  return (
    <>
      <Stack spacing={2} display={"flex"} alignItems={"start"} justifyContent={"start"} paddingTop={1}>
        <IconButton color="primary" onClick={() => navStep(isNumbered === "true" ? 2 : 3)}>
          {currentStep > 1 && <ArrowCircleLeft color="primary" sx={{width: "30px", height: "30px"}} />}
        </IconButton>
      </Stack>

      <Stack direction={"row"} spacing={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Box width={"300px"}>
          {isNumbered === "true" && <img src="/header/computer-numbered.svg" alt="Numbered" />}
          {isNumbered === "false" && <img src="/header/computer-named.svg" alt="Named" />}
        </Box>
        <Fade in={true} timeout={1000}>
          <Stack spacing={2} display={"flex"} alignItems={"start"} justifyContent={"center"} width={{xs: 300, md: 500}}>
            <Typography fontSize={20} fontWeight={600}>
              Great! Here is what we will be creating:
            </Typography>
            <Typography fontSize={18}>
              {isNumbered === "true" ? "A numbered company" : "A named company"} <strong>{reqCompanyName}</strong> in Ontario.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Sounds good, let&apos;s go!
            </Button>
            {isNumbered === "true" && (
              <Button variant="contained" color="error" onClick={() => navStep(isNumbered === "true" ? 2 : 3)}>
                I Want a Named Company Instead
              </Button>
            )}
            {isNumbered === "false" && (
              <Button variant="contained" color="error" onClick={() => navStep(2)}>
                I Want a numbered Company Instead
              </Button>
            )}
            {hasNuans === "true" && (
              <Typography bgcolor={"gray"} color={"white"} padding={2} borderRadius={5} fontSize={14}>
                You have told us that you have a NUANS Name Search Report. We will use that to check the availability of the name. Please have that
                ready for the next step.
              </Typography>
            )}
            {/* <Box display={"flex"} justifyContent={"end"} width={"100%"}>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          </Box> */}
          </Stack>
        </Fade>
      </Stack>
    </>
  );
}
